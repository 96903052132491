import {
  computed,
  nextTick,
  ref,
} from 'vue'

import { useAjax } from '@composables/useAjax.js'
import { useI18n } from '@shared/i18n.js'
import { generateUrl } from '@shared/util.js'

const cachePrefectures = ref({ rent: [], sale: [] })

export const usePropertySearch = (args = {}) => {
  const { t, tm } = useI18n()

  const bedrooms = ref(null)
  const bedroomsFrom = ref(null)
  const cities = ref([])
  const location = ref(null)
  const priceFrom = ref('')
  const priceTo = ref('')
  const propertyType = ref([])
  const railways = ref([])
  const stations = ref([])
  const type = ref('sale')

  const enumBedrooms = tm('activerecord.enums.property.bedrooms')

  /**
   * 都道府県の一覧取得
   */
  const getPrefectures = async () => {
    const url = generateUrl('prefectures')
    const { callGet } = useAjax()
    cachePrefectures.value = await callGet(url)

    if (args.afterGetPrefectures) {
      nextTick(() => args.afterGetPrefectures())
    }
  }

  /**
   * 間取りの選択肢
   */
  const optionsBedrooms = Object.keys(enumBedrooms).filter((key) => key < 100)
                                .map((key) => ({ value: key, text: enumBedrooms[key] }))
                                .sort((a, b) => parseInt(a.value) - parseInt(b.value))

  /**
   * 都道府県の選択肢
   */
  const optionsPrefectures = computed(() => cachePrefectures.value[type.value])

  /**
   * 価格の選択肢（売買・賃貸で切り替え）
   */
  const optionsPrice = computed(() => {
    return {
      rent: [10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000, 150000, 200000, 300000, 400000, 500000, 600000, 700000, 800000, 900000, 1000000, 1500000, 2000000],
      sale: [5000000, 10000000, 20000000, 30000000, 40000000, 50000000, 80000000, 100000000, 300000000, 500000000, 800000000, 1000000000]
    }[type.value]
  })

  /**
   * 物件タイプの選択肢
   */
  const optionsPropertyTypes = computed(() => {
    const propertyTypes = window.app.common.propertyTypes
    const keys = {
      rent: [1, 2, 12, 5, 4, 7, 8, -1],
      sale: [1, 2, 6, 10, 11, 3, 12, -1],
    }[type.value]

    return keys.map((key) => ({
      value: propertyTypes[key] ? propertyTypes[key].replace(/_/, '-') : key,
      text: t(`activerecord.enums.property.property_type.${key}`)
    }))
  })

  /**
   * 物件一覧のURL生成
   */
  const propertiesUrl = () => {
    const format = ":property_type-for-:type/:location"
    const propertyTypes = propertyType.value ? propertyType.value.filter((v) => v != 'property') : [] // なぜかスマホ版の時に入ってくる？
    const allPropertyTypes = propertyTypes.length == 0 || propertyTypes.includes(-1)
    const path = format.replace(":type", type.value)
                       .replace(":property_type", !allPropertyTypes && propertyTypes.length == 1 ? propertyTypes[0] : 'property')
                       .replace(":location", location.value || '')
    const url = generateUrl(path, '')

    // 検索パラメータ
    if (!allPropertyTypes && propertyTypes.length > 1) {
      propertyTypes.forEach((v) => url.searchParams.append('property_types[]', v))
    }
    if (bedrooms.value && Array.isArray(bedrooms.value)) {
      bedrooms.value.forEach((v) => url.searchParams.append('bedrooms[]', v))
    }
    if (bedroomsFrom.value && bedroomsFrom.value != '') {
      url.searchParams.append('bedrooms_from', bedroomsFrom.value)
    }
    if (priceFrom.value && priceFrom.value != '') {
      url.searchParams.append('price_from', priceFrom.value)
    }
    if (priceTo.value && priceTo.value != '') {
      url.searchParams.append('price_to', priceTo.value)
    }
    if (cities.value && cities.value.length > 0) {
      url.searchParams.append('cities', cities.value.join(','))
    }
    if (stations.value && stations.value.length > 0) {
      url.searchParams.append('stations', stations.value.join(','))
    }
    else if (railways.value && railways.value.length > 0) {
      url.searchParams.append('railways', railways.value.join(','))
    }
    if (window.location.href.match(/m=t/)) {
      url.searchParams.append('m', 't')
    }

    return url.toString()
  }

  getPrefectures()

  return {
    // variables
    bedrooms,
    bedroomsFrom,
    cities,
    location,
    priceFrom,
    priceTo,
    propertyType,
    railways,
    stations,
    type,
    // methods
    optionsBedrooms,
    optionsPrice,
    optionsPrefectures,
    optionsPropertyTypes,
    propertiesUrl,
  }
}
